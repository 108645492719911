* {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  color: black;
  font-size: 1.8rem;
  line-height: 1.6;
  overflow: auto;
}
h1 {
  font-size: 4.5rem;
}
h2 {
  font-size: 3.5rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.75;
}
p {
  font-size: 1.6rem;
}
a {
  text-decoration: none;
}
.bold {
  font-weight: 700;
}
.backgroundImage {
  width: 90%;
  height: 90vh;
  position: fixed;
  background-size: cover;
  background-position: center;
  z-index: -1;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: 'Roboto', sans-serif;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
header {
  position: sticky;
  top: 0;
  z-index: 9;
  background: white;
  border-bottom: 1px solid lightsteelblue;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 100;
  height: 75px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0% 0.8%;
}
header nav {
  min-width: 30%;
  max-width: 80%;
  display: flex;
  justify-content: flex-end;
}
header nav h3 {
  font-size: 1.3rem;
  font-weight: 800;
  color: #1351a8;
  margin: 0 10px;
}
header nav h3:hover {
  color: #F06A67;
}
@media (max-width: 1024px) {
  header nav h3 {
    font-size: 1rem;
  }
}
@media (max-width: 500px) {
  header nav h3 {
    margin: 10px 5px 0 0;
    font-size: 0.75rem;
  }
}
header nav button {
  width: 110px;
  background: #ffffff;
  padding: 2%;
  border: 3px solid #1351a8;
  color: #1351a8;
  font-size: 1rem;
  font-weight: 501;
}
header nav button:hover {
  background: #F06A67;
  border: 3px solid #F06A67;
  color: #ffffff;
}
@media (max-width: 800px) {
  header nav button {
    padding: 1%;
  }
}
@media (max-width: 500px) {
  header nav button {
    padding: 1% 0;
    font-size: 0.75rem;
    width: 80px;
  }
}
@media (max-width: 800px) {
  header nav {
    margin: 0 auto;
    width: 100%;
    min-width: 475px;
    max-width: 100%;
  }
}
@media (max-width: 500px) {
  header nav {
    display: none;
  }
}
@media (max-width: 800px) {
  header {
    height: 80px;
  }
}
@media (max-width: 500px) {
  header {
    margin: 0;
    padding: 0;
    height: 60px;
  }
}
.headerLogo {
  max-width: 75%;
}
.headerLogo div {
  display: flex;
}
.headerLogo h1 {
  color: #F06A67;
  font-size: 2rem;
  font-weight: 800;
  margin: auto 0;
}
@media (max-width: 500px) {
  .headerLogo h1 {
    font-size: 1.3rem;
  }
}
.headerLogo h4 {
  color: #1351a8;
  font-size: 1.1rem;
  font-weight: 1000;
  margin: 13px 0 0 5px;
}
@media (max-width: 1024px) {
  .headerLogo h4 {
    font-size: 1.1rem;
    margin: 13px 0 0 5px;
  }
}
@media (max-width: 800px) {
  .headerLogo h4 {
    font-size: 1rem;
    margin: 14px 0 0 5px;
  }
}
@media (max-width: 500px) {
  .headerLogo h4 {
    margin: 7px 0 0 5px;
  }
}
@media (max-width: 500px) {
  .headerLogo {
    margin: 0 auto;
  }
}
.headerIcon {
  display: none;
  font-size: 26px;
  color: #7289da;
}
@media (max-width: 500px) {
  .headerIcon {
    display: unset;
  }
}
.contact {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 500px) {
  .contact {
    display: none;
  }
}
.contactIcon1 {
  margin: 0 10px;
  font-size: 45px;
  color: #7289da;
}
@media (max-width: 800px) {
  .contactIcon1 {
    margin: 0 5px 0 0;
  }
}
@media (max-width: 500px) {
  .contactIcon1 {
    margin: 0 10px 0 0;
  }
}
.contactIcon2 {
  margin: 0 10px 0 10px;
  font-size: 45px;
  color: #7289da;
}
@media (max-width: 800px) {
  .contactIcon2 {
    margin: 0 5px 0 0;
  }
}
.four-oh-four {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.four-oh-four img {
  width: 100%;
  max-width: 500px;
  margin: 0 auto 20px auto;
}
.four-oh-four p {
  text-align: center;
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-container h3 {
  color: #F06A67;
  font-size: 30px;
  padding: 1%;
  margin: 3%;
}
.login-container form {
  width: 100%;
}
@media (max-width: 500px) {
  .login-container form {
    max-width: 90%;
  }
}
.form-inputs-login {
  width: 80%;
  margin: 0 auto;
}
.form-inputs-login input {
  padding: 2%;
  border: 1px solid #1351a8;
  border-radius: 10px;
  width: 100%;
  margin: 3.5% auto;
  font-weight: bold;
}
.form-inputs-login input::placeholder {
  color: #1351a8;
  background: #ffffff;
  font-weight: 500 !important;
}
.form-inputs-login input:focus {
  outline: none;
  border: 1px solid #F06A67;
  color: #1351a8;
  opacity: 1;
}
.remember-and-submit-login {
  margin-top: 5%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #1351a8;
}
.remember-and-submit-login button {
  background: #1351a8;
  color: #ffffff;
  padding: 3%;
  margin-bottom: 4%;
  width: 60%;
  border-radius: 8px;
  font-weight: bold;
  border: #1351a8;
}
.remember-and-submit-login button:hover {
  background: #114791;
  border: #114791;
}
@media (max-width: 500px) {
  .remember-and-submit-login {
    max-width: 95%;
  }
}
.remember-me-login {
  padding: 1%;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.remember-me-login input {
  color: #1351a8;
  background: orange;
  border: #1351a8;
}
.remember-me-login label {
  color: #1351a8;
  font-size: 1.25rem;
}
.google-login {
  background: #ffffff;
  color: #1351a8;
  padding: 3%;
  border-radius: 8px;
  font-weight: bold;
  border: 1px solid #1351a8;
  width: 60%;
}
.google-login:hover {
  background: #fafafa;
  color: #F06A67;
  border: 1px solid #1351a8;
}
.return-login {
  font-size: 12px;
  color: #1351a8;
  margin-top: 4%;
  margin-bottom: 4%;
  font-weight: bold;
}
.return-login:hover {
  color: #114791;
  cursor: pointer;
}
.registerParent {
  border: 1px solid lightsteelblue;
  box-shadow: 4px 4px rgba(0, 0, 0, 0.2);
  margin: 8% auto 0 auto;
  background: #ffffff;
  border-radius: 10px;
  width: 40%;
}
@media (max-width: 500px) {
  .registerParent {
    width: 85%;
    margin-top: 18%;
  }
}
@media (max-width: 800px) {
  .registerParent {
    width: 70%;
  }
}
@media (max-width: 1024px) {
  .registerParent {
    width: 50%;
    margin-top: 10%;
  }
}
@media (min-width: 1400px) {
  .registerParent {
    width: 30%;
  }
}
.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.register-container h3 {
  color: #F06A67;
  font-size: 30px;
  padding: 1%;
  margin: 3%;
}
.register-container form {
  width: 100%;
}
@media (max-width: 500px) {
  .register-container form {
    max-width: 90%;
  }
}
.register-container button {
  background: #1351a8;
  color: #ffffff;
  border: #1351a8;
  border-radius: 8px;
  font-weight: bold;
  width: 50%;
  margin: 0 auto 3% auto;
  padding: 3%;
}
.register-container button:hover {
  background: #114791;
  border: #114791;
}
.form-inputs-register {
  width: 90%;
  margin: 0 auto;
}
.form-inputs-register input {
  padding: 2%;
  border: 1px solid #1351a8;
  border-radius: 10px;
  width: 100%;
  margin: 3.5% auto;
  font-weight: bold;
}
.form-inputs-register input::placeholder {
  color: #1351a8;
  font-weight: 500;
  background: #ffffff;
}
.form-inputs-register input:focus {
  outline: none;
  border: 1px solid #F06A67;
  color: #1351a8;
  font-weight: 500;
}
.register-names {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.register-names input {
  width: 49%;
}
.google-registerDiv {
  border-top: 1px solid lightsteelblue;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
#google-login {
  background: #ffffff;
  color: #1351a8;
  margin: 3% auto;
  padding: 3%;
  border-radius: 8px;
  font-weight: bold;
  border: 1px solid #1351a8;
  width: 50%;
}
#google-login:hover {
  background: #fafafa;
  color: #F06A67;
  border: 1px solid #1351a8;
}
.emailOptIn {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 18.57px;
}
.emailOptIn input {
  margin: 0;
  background: blue;
  width: 20px;
}
.emailOptIn p {
  color: #F06A67;
  font-weight: 550;
  margin: 0 10px;
  min-width: 180px;
}
.emailOptIn span {
  color: #1351a8;
  margin: 0;
}
.registerEmailSent {
  width: 100%;
  min-height: 150px;
  text-align: center;
}
.registerEmailSent h2 {
  padding: 1%;
}
.registerEmailSent button {
  margin-top: 50px;
  cursor: pointer;
}
