
.test{
    position: relative;
    border: 1px solid black;
    border-radius: 6px;
    background-color: lightblue;
    width: 600px;
    height: 150px;
    margin: 20px auto;
    cursor: pointer;
    
    p{
        position: absolute;
        bottom: 20px;
        width: 100%;
        text-align: center;
    }
}