

.reactCrop{
    max-width: 100%;
    max-height: 100%;
}

.cropContainer{
    position: relative;
    width: 100%;
    min-height: 400px;
    height: 100%;
    max-height: 50vh;
    >div{
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 50vh;
        >img{
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.controls{
    display: flex;
    width: 100%;
    >div{ flex-grow: 1; }
}