
.files{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 16px 0;
    
    h3{ margin-bottom: 8px; width: 100%; }
    li{
        border: 1px solid black;
        display: flex;
        flex-direction: column;
        list-style: none;
        max-width: 300px;
        margin: 0 0 16px 0;
        padding: 8px;

        img{
            padding-bottom: 8px;
            border-bottom: 1px solid black;
            max-width: 100%;
            max-height: 300px;
            object-fit: contain;
        }
        p{
            margin: 4px 4px 0 4px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 298px;
            &:last-of-type{
                margin-bottom: 8px;
            }
        }
        div{
            display: flex;
            justify-content: space-evenly;
            margin-top: auto;
            button{ cursor: pointer; }
        }
    }
}