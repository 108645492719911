

.modalOverlay{
    background-color: hsla(0, 0%, 100%, .5);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    padding: 40px;
    z-index: 99999;
}

.modalContent{
    background-color: lawngreen;
    background-color: lightgrey;
    border: 1px solid black;
    position: absolute;
    top: 40px;
    width: 100%;
    max-width: calc(100% - 80px);
    height: 100%;
    max-height: calc(100vh - 80px);
    padding: 40px;
    z-index: 99999;
    h2{ margin-bottom: 16px; }
}

.modalButtonRow{
    background-color: pink;
    display: flex;
    justify-content: space-evenly;
    width: 300px;
    margin: 16px auto;
    button{
        border-radius: 6px;
    }
}